import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"

const HeartlessPage = ({ data, location }) => {
  const posts = data.allMarkdownRemark.edges
  return (
    <Layout location={location}>
      <SEO title="Heartless – Game In Progress" />
      <div id="heartless" className="text-center inner-shadow header">
        <h1 className="container">HEARTLESS</h1>
        <h3 className="container">Dev Blog</h3>
      </div>
      <div className="text-center">
        <p className="container">
          <b>Heartless</b> (name tbd) is still in development. Check-in here for
          monthly updates!
        </p>
      </div>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug}>
            <div className="post-content">
              <header>
                <h2 className="container" style={{ paddingBottom: "0" }}>
                  <Link to={node.fields.slug}>{title}</Link>
                </h2>
                <h5 className="date container">{node.frontmatter.date}</h5>
              </header>
              <section
                className="container"
                dangerouslySetInnerHTML={{
                  __html: node.html,
                }}
              />
            </div>
          </article>
        )
      })}
    </Layout>
  )
}

export default HeartlessPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { tags: { eq: "Heartless" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
